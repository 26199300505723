import { Box } from "@mui/material";
import EventsCard from "./EventsCard/EventsCard";
import Pb from "../myButton/Pb/Pb";
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import styles from './Events.module.css'
import Spinner from "../Spinner/Spinner";

export default function Events({ filter }) {
  const languageLocalId = localStorage.getItem('langId');
  const { language } = useSelector(state => state.language)
  const [eventsAll, setEventsAll] = useState(false)
  const [setErr, err] = useState('')
  // let filteredArr = eventsAll.slice(0, 3)
  useEffect(() => {
    // https://emkiproductions-apim.azure-api.net/
    // const getUsers = async () => {
    fetch('https://emkiproduction.azurewebsites.net/api/Announcement/translation', {
      headers: {
        'languageId': languageLocalId ? languageLocalId : 5
      } 
    })
      .then(res => res.json())
      .then(data => {
        setEventsAll(data.reverse())
      })
      .catch(err => setErr(err))
    // }
    // getUsers()
  }, [language])
  console.log(eventsAll);

  return (
    <>

      {eventsAll ?
        <>
          {filter ?

            <Box className=
              'mx-auto w-11/12 sm:w-4/5 mb-6 xl:mb-0 pt-16 sm:pt-16 md:pt-16 xl:pt-16 pb-0 sm:pb-0 md:pb-0 xl:pb-0 '
            >
              <Pb button='DiscoverEvents' />
              <Box className={styles.cardsContainerHome}>
                {eventsAll?.map((q, i) =>
                  <EventsCard language={languageLocalId} key={i} event={q} />)}
              </Box>

            </Box>
            :

            //    <Box className=
            //    'mx-auto w-11/12 sm:w-4/5 mb-6 xl:mb-0 pt-32 sm:pt-40 md:pt-40 xl:pt-40 pb-32 sm:pb-40 md:pb-40 xl:pb-40 '
            //  >
            <Box className='mx-auto w-11/12 sm:w-4/5 mb-6 xl:mb-0 pt-28 sm:pt-28  md:pt-40 xl:pt-40'>

              <Pb button='Events' />
              <Box className={styles.cardsContainer}>
                {eventsAll?.map((q, i) =>
                  <EventsCard language={languageLocalId} key={i} event={q} />
                )}
              </Box>
            </Box>
          }
        </>
        :

        <Box>

          <Spinner />

        </Box>

      }


    </>
  )
}

