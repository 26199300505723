import { Box } from "@mui/material";
import BlogCard from "./BlogCard/BlogCard";
import Pb from "../myButton/Pb/Pb";
import { useSelector } from 'react-redux';
import { useState, useEffect } from "react";
import styles from './Blog.module.css'
import Spinner from "../Spinner/Spinner";


export default function Blog({ filter }) {
  const languageLocalId = localStorage.getItem('langId');
  const [err, setErr] = useState('')
  const { language } = useSelector(state => state.language)
  const [blogAll, setBlogAll] = useState(false)

  useEffect(() => {
    // const getUsers = async () => {
    fetch('https://emkiproduction.azurewebsites.net/api/Post/translation/', {
      headers: {
        'languageId': languageLocalId ? languageLocalId : 5
      }
    })
      .then(res => res.json())
      .then(data => {
        setBlogAll(data.reverse())
      })
      .catch(err => setErr(err))
    // }
    // getUsers()
  }, [language])





  return (
    <>

      {blogAll ?
        <>
          {filter ?

            <Box className=
              'mx-auto w-11/12 sm:w-4/5  mb-6 xl:mb-0 pt-16 sm:pt-16 md:pt-16 xl:pt-16 pb-0 sm:pb-0 md:pb-0 xl:pb-0 '
            >
              <Pb button='Blog' />
              <Box className={styles.cardsContainerHome}>
                {blogAll?.map((q, i) =>
                  <BlogCard key={i} blog={q} />)}
              </Box>
            </Box>
            :

            //    <Box className=
            //    'mx-auto w-11/12 sm:w-4/5 mb-6 xl:mb-0 pt-32 sm:pt-40 md:pt-40 xl:pt-40 pb-32 sm:pb-40 md:pb-40 xl:pb-40 '
            //  >
            <Box className='mx-auto w-11/12 sm:w-4/5 mb-6 xl:mb-0 pt-28 sm:pt-28  md:pt-40 xl:pt-40'>

              <Pb button='Blog' />
              <Box className={styles.cardsContainer}>
                {blogAll?.map((q, i) =>
                  <BlogCard key={i} blog={q} />
                  )}
              </Box>
            </Box>
          }
        </>

        :
        <Box>

          <Spinner />

        </Box>

      }

    </>
  )
}