import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import styles from './BlogInfoCard.module.css'
import Blog from '../Blog';
import {useParams} from "react-router-dom";
export default function BlogInfoCard(){
   const {id} = useParams();
    // const { selectedBlog } = useSelector(state => state.selectedBlog)
    const selectedBlog = JSON.parse(localStorage.getItem("blog"))
// const { language } = useSelector(state => state.language)
const language = localStorage.getItem("langId");
const [blogAll, setBlogAll ] = useState([])
useEffect(() => {
  fetch(`https://emkiproduction.azurewebsites.net/api/PostTranslation?postId=${id}&languageId=${language ? language : 5}`, {
        headers: {
            'languageId': language ? language : 5
        }
      })
      .then(res => res.json())
      .then(data => {
        setBlogAll(data)
      })
      .catch(err => console.log(err))
})
// useEffect(() => {
//   fetch(`https://emkiproduction.azurewebsites.net/api/PostTranslation?postId=${id}&languageId=${language ? language : 5}`, {
//       headers: {
//           'languageId': language ? language : 5
//       }
//     })
//     .then(res => res.json())
//     .then(data => {
//       setBlogAll(data)
//     })
//     .catch(err => console.log('err'))
// }, [selectedBlog])
    return(
        <>
             <Box className='

mx-auto w-11/12 sm:w-4/5 mb-6 xl:mb-0 pt-32 sm:pt-40  md:pt-40 xl:pt-40
'>
            <Box >
      <Box>
        <CardMedia
          component="img"
          // width="100%"
          className={styles.infoImg}
          // height="140"
          image={blogAll?.imageSrc}
          alt={blogAll?.title}
        />
        <Box style={{border: 0}}>
          <Typography className={styles.infoTitle} gutterBottom component="div">
          {blogAll?.title}
          </Typography>

          {blogAll?.content?.split('bosluq').map((q, i) => 
              <Typography key={i} className={styles.infoText} >
            {q}
          </Typography>
          ) }
        {/* setBlogAll( blogAll.content.split('boşluq') = `<br/>`) */}

        </Box>
      </Box>
    </Box>
    </Box>

        </>
    )
}