import { Box } from "@mui/material"
import AboutHome from "./AboutHome"
import Slider from "./AboutSlider/Slider"
export default function About() {
    return (
        <>
            <AboutHome />
            <Slider/>
        </>
    )
}