import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
export default function Spinner(){
    return (
        // <Box style={{backgroundColor: 'red'}}>

        <Box className='w-full flex justify-center items-center ' style={{height: 'calc(100vh - 108px)'}}>
        <CircularProgress style={{color: '#e32682'}} size={50}/>
        {/* </Box> */}
        </Box>
    )
}